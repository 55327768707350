import { API_URL } from "../../environments/environment";
import {AuthService} from "./auth.service";
import {RequestService} from "./request.service";

export class DoctorApiService {
  constructor() {
  }

  async getDoctorListData() {
    const authService = new AuthService();
    const requestService = new RequestService();
    const params = '?page=1&per_page=25';
    const accessToken = localStorage.getItem('Access-token');

    return new Promise((resolve, reject) => {
      authService.setSourceToken(API_URL + 'api/v1/telemedicine/doctors' + params, {
        headers: {
          'Access-token': accessToken
        },
      }, (url, option) => {
        requestService.request(url, {
          method: 'GET',
          headers: option.headers,
          bode: null
        }).then((res) => {
          resolve(JSON.parse(res.response));
        }, (error) => {
          reject(error);
        });
      });
    });
  }
}
