import { APP_ID } from "../environments/environment";

import { CallService } from './services/call.service';
import { DoctorController } from './controllers/doctor.controller';
import { DoctorService } from './services/doctor.service';
import { NoSleepService } from './services/no-sleep.service';
import { CallController } from "./controllers/call.controller";

(async function () {
  // Elements
  let bodyElement = document.getElementsByTagName('body')[0];
  let doctorsListElement = document.querySelector('.doctors-list');
  let callWindowElement = document.querySelector('.video-call');
  let notFoundElement = document.querySelector('.not-found');
  let emptyDoctorListElement = document.querySelector('.empty-doctor-list');
  let localVideoElement = document.getElementById('local_video_element_id');
  let remoteVideoElement = document.getElementById('remote_video_element_id');
  let microphoneBtnElement = document.querySelector('.call-panel__microphone');
  let microphoneElement = document.querySelector('.video-call__microphone');
  let muteMicrophoneElement = document.querySelector('.video-call__mute-microphone');

  let cameraBtnElement = document.querySelector('.call-panel__camera');
  let cameraElement = document.querySelector('.video-call__camera');
  let cameraOffElement = document.querySelector('.video-call__camera-off');

  let audioBtnElement = document.querySelector('.call-panel__audio');
  let audioLabelElement = document.querySelector('.call-panel__audio-label');
  let speakerphoneElement = document.querySelector('.video-call__speakerphone');
  let headphoneElement = document.querySelector('.video-call__headphone');

  let endCallBtnElement = document.querySelector('.call-panel__end-call');
  let callTimerElement = document.querySelector('.video-call-info__timer');
  let doctorNameElement = document.querySelector('.video-call-info__doctor-name');
  let confirmStartCallElement = document.querySelector('.confirm-start-call');
  let callBtnRejectElement = document.getElementById('confirm-start-call__btn-reject');
  let callBtnConfirmElement = document.getElementById('confirm-start-call__btn-call');
  let startVideoBtnElement = document.getElementById('start-video-btn');
  let waitingCallElement = document.querySelector('.notification__waiting-call');
  let lockScreenElement = document.querySelector('.notification__lock-screen');

  let noSoundAvailableElement = document.querySelector('.notification__no-sound-available');
  let noSoundCloseBtnElement = document.querySelector('.notification__no-sound-available-close-btn');
  let callDeclinedElement = document.querySelector('.notification__call-declined');
  let callDeclinedCloseBtnElement = document.querySelector('.notification__call-declined-close-btn');
  let timeOutElement = document.querySelector('.notification__time-out');
  let timeOutCloseBtnElement = document.querySelector('.notification__time-out-close-btn');
  let noPermissionElement = document.querySelector('.notification__no-permission');
  let noPermissionCloseBtnElement = document.querySelector('.notification__no-permission-close-btn');

  let listUpdateInterval;
  let doctorData;

  // Services
  const callService = new CallService(APP_ID);
  const doctorService = new DoctorService();
  const noSleepService = new NoSleepService();

  // Controllers
  const callController = new CallController(callTimerElement, waitingCallElement);
  const doctorController = new DoctorController();

  // TODO: This should be moved to call controller
  function openCallWindow() {
    startVideoBtnElement.click();
    callController.setCallTimer();
    callController.hideWaitingConnection();
    doctorsListElement.style.display = 'none';
    callWindowElement.style.display = 'flex';
    notFoundElement.style.display = 'none';
    emptyDoctorListElement.style.display = 'none';
    bodyElement.style.backgroundColor = '#000';
  }

  function openDoctorList() {
    doctorsListElement.style.display = 'flex';
    callWindowElement.style.display = 'none';
    notFoundElement.style.display = 'none';
    emptyDoctorListElement.style.display = 'none';
  }

  function openNotFound() {
    doctorsListElement.style.display = 'none';
    callWindowElement.style.display = 'none';
    notFoundElement.style.display = 'block';
    emptyDoctorListElement.style.display = 'none';
    bodyElement.style.backgroundColor = '#fff';
  }

  function openConfirmStartCall() {
    confirmStartCallElement.style.display = 'flex';
  }

  function rejectCallElement() {
    confirmStartCallElement.style.display = 'none';
    openNotFound();
  }

  function showCallDeclinedElement() {
    callDeclinedElement.style.display = 'block';
  }

  function closeCallDeclinedElement() {
    callDeclinedElement.style.display = 'none';
  }

  function showTimeOutElement() {
    timeOutElement.style.display = 'block';
  }

  function closeTimeOutElement() {
    timeOutElement.style.display = 'none';
  }

  function showNoPermissionElement() {
    noPermissionElement.style.display = 'block';
  }

  function closeNoPermissionElement() {
    noPermissionElement.style.display = 'none';
  }

  function closeNoSoundAvailable() {
    noSoundAvailableElement.style.display = 'none';
  }

  function confirmCallElement() {
    rejectCallElement();
    startVideoBtnElement.click();
    startCall(doctorData);
  }

  function startCall(doctorData) {
    setDoctorsName(doctorData.callName);
    callController.showWaitingConnection(listUpdateInterval);
    audioBtnElement.onclick = function () {
      toggleAudioBtn();
    };
    callService.startCall(
      doctorData.clientId,
      localVideoElement,
      remoteVideoElement,
      function () {
        toggleMicrophoneBtn();
      }, function () {
        toggleCameraBtn();
      }, function () {
        toggleAudioBtn();
      }, function () {
        openCallWindow();
      }, function (status) {
        if (status === 'declined') {
          showCallDeclinedElement();
          openNotFound();
        } else if (status === 'no_answer') {
          showTimeOutElement();
          openNotFound();
        } else if (status === 'no_permission') {
          showNoPermissionElement();
        }
        callController.hideWaitingConnection();
        noSleepService.removeNoSleep();
      }, function () {
        callController.hideWaitingConnection();
        openDoctorList();
        initDoctorsList();
        listUpdateInterval = setInterval(() => {
            initDoctorsList();
        }, 15000);
      },
      microphoneBtnElement,
      cameraBtnElement,
      audioBtnElement,
      audioLabelElement,
      endCallBtnElement,
      noSoundAvailableElement,
      callController.timerInterval);
  }

  function initOnclick() {
    callBtnRejectElement.onclick = function () {
      rejectCallElement();
    };
    callBtnConfirmElement.onclick = function () {
      confirmCallElement();
    };
    startVideoBtnElement.onclick = function () {
      localVideoElement.play();
      remoteVideoElement.play();
    }
    noSoundCloseBtnElement.onclick = function () {
      closeNoSoundAvailable();
    };
    callDeclinedCloseBtnElement.onclick = function () {
      closeCallDeclinedElement();
    };
    timeOutCloseBtnElement.onclick = function () {
      closeTimeOutElement();
    };
    noPermissionCloseBtnElement.onclick = function () {
      closeNoPermissionElement();
    };
  }

  function getBrowser() {
    let ua=navigator.userAgent,tem,M=ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
      tem=/\brv[ :]+(\d+)/g.exec(ua) || [];
      return {name:'IE',version:(tem[1]||'')};
    }
    if(M[1]==='Chrome'){
      tem=ua.match(/\bOPR|Edge\/(\d+)/)
      if(tem!=null)   {return {name:'Opera', version:tem[1]};}
    }
    M=M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem=ua.match(/version\/(\d+)/i))!=null) {M.splice(1,1,tem[1]);}
    return {
      name: M[0],
      version: M[1]
    };
  }

  function setResizeForIOS() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  function toggleMicrophoneBtn() {
    if (microphoneElement.style.display === '' || microphoneElement.style.display === 'inline-block') {
      microphoneElement.style.display = 'none';
      muteMicrophoneElement.style.display = 'inline-block';
      microphoneBtnElement.classList.add('disable');
    } else {
      microphoneElement.style.display = 'inline-block';
      muteMicrophoneElement.style.display = 'none';
      microphoneBtnElement.classList.remove('disable');
    }
  }

  function toggleCameraBtn() {
    if (cameraElement.style.display === '' || cameraElement.style.display === 'inline-block') {
      cameraElement.style.display = 'none';
      cameraOffElement.style.display = 'inline-block';
      cameraBtnElement.classList.add('disable');
    } else {
      cameraElement.style.display = 'inline-block';
      cameraOffElement.style.display = 'none';
      cameraBtnElement.classList.remove('disable');
    }
  }

  function toggleAudioBtn() {
    if (speakerphoneElement.style.display === '' || speakerphoneElement.style.display === 'inline-block') {
      speakerphoneElement.style.display = 'none';
      headphoneElement.style.display = 'inline-block';
    } else {
      speakerphoneElement.style.display = 'inline-block';
      headphoneElement.style.display = 'none';
    }
  }

  function openEmptyDoctorList() {
    doctorsListElement.style.display = 'none';
    callWindowElement.style.display = 'none';
    notFoundElement.style.display = 'none';
    emptyDoctorListElement.style.display = 'block';
  }

  function accessToken() {
    return new Promise((resolve, reject) => {
      const urlArr = window.location.href.split('/');
      localStorage.setItem('Access-token', urlArr[urlArr.length - 1]);
      if (urlArr[urlArr.length - 1]) {
        openDoctorList();
        resolve(urlArr[urlArr.length - 1]);
      } else {
        openNotFound();
        reject(new Error('access token not found'));
      }
    });
  }

  function initDoctorsList() {
    Promise.all([
      accessToken(),
      doctorService.getDoctors()
    ]).then(([_, doctors]) => {
      if (doctors.length > 0) {
        openDoctorList();
        doctorController.renderListOfDoctors(doctorsListElement, doctors, async (doctor) => {
          doctorData = doctor;
          const browser = getBrowser();
          if (browser.name === 'Safari' && browser.version < 10) {
            openConfirmStartCall();
          } else {
            startCall(doctorData);
          }
        });
      } else {
        openEmptyDoctorList();
      }
    })
      .catch(error => {
        console.log(`Call Failed : ${error.message}`);

        if (error.message === 'server request failed') {
          openNotFound();
        }
      })
  }

  function init() {
    // showNoSoundAvailable();
    noSleepService.initNoSleep(startVideoBtnElement);
    checkDevice();
    lockPageOrientation();
    setResizeForIOS();
    initDoctorsList();
    listUpdateInterval = setInterval(() => {
      initDoctorsList();
    }, 15000);
    initOnclick();
  }

  function setDoctorsName(name) {
    doctorNameElement.innerHTML = name;
  }

  function lockPageOrientation() {
    if (checkSafari) {
      window.addEventListener('orientationchange', deviceOrientation);
      deviceOrientation();
    } else {
      window.screen.orientation
        .lock("portrait")
        .then(
          success => {},
          failure => {
            window.addEventListener('orientationchange', deviceOrientation);
            deviceOrientation();
          }
        );
    }
  }

  function deviceOrientation() {
    bodyElement.classList = '';
    switch(window.orientation) {
      case 90:
        lockScreenElement.style.display = 'flex';
        break;
      case -90:
        lockScreenElement.style.display = 'flex';
        break;
      default:
        lockScreenElement.style.display = 'none';
        break;
    }
  }

  function checkSafari() {
    let ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      return ua.indexOf('chrome') <= -1;
    }
    return false;
  }

  function checkDevice() {
    if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      audioLabelElement.style.display = 'none';
    }
  }

  init();

}());
