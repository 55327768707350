import { RequestService } from "./request.service";
import { TokenService } from "./token.service";

export class AuthService {
  constructor() {
    this.requestService = new RequestService();
    this.tokenService = new TokenService();
  }

  // TODO: Remove callback. Use Promise
  async setSourceToken(url, options, callback) {
    callback(url, options);
  }
}
