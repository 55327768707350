export class CallController {

  constructor(callTimer, waitingCall) {
    this.callTimerElement = callTimer;
    this.waitingCallElement = waitingCall;
  }

  // Waiting connection
  showWaitingConnection(listUpdateInterval) {
    clearInterval(listUpdateInterval);
    this.waitingCallElement.style.display = 'flex';
  }

  hideWaitingConnection() {
    this.waitingCallElement.style.display = 'none';
  }

  // Call timer
  setCallTimer() {
    let i = 1;
    this.timerInterval = setInterval(() => {
      let sec_num = i;
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      let seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (minutes < 10) {
        minutes = '0' + minutes;
      }
      if (seconds < 10) {
        seconds = '0' + seconds;
      }
      this.callTimerElement.innerHTML = minutes + ':' + seconds;
      i++;
    }, 1000);
  }
}
