import { DoctorModel } from '../models/doctor.model';
import { DoctorApiService } from "./doctor.api.service";

export class DoctorService {

  async getDoctors() {
    const doctorApiService = new DoctorApiService();
    const hiddenStatus = 'offline';

    return new Promise((resolve, reject) => {
      doctorApiService.getDoctorListData().then((res) => {
        const doctorList = res.data
          .filter(doctor => doctor.status !== hiddenStatus)
          .map(doctor => {
          return new DoctorModel(
            doctor.id,
            doctor.id,
            doctor.avatar_url,
            (doctor.first_name + ' ' + doctor.last_name),
            doctor.full_name,
            doctor.description,
            doctor.category,
            doctor.experience,
            doctor.status,
          )
        });
        resolve(doctorList);
      }, (err) => {
        reject(err);
      });
    });
  }
}
