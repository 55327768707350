import doctorTemplate from '../templates/doctor-item.template';

export class DoctorController {
  renderListOfDoctors(rootElement, doctors, doctorClickCallback) {
    rootElement.textContent = '';
    doctors.forEach(doctor => {
      const templateNode = doctorTemplate(doctor, doctorClickCallback);
      rootElement.appendChild(templateNode);
    });
  }
}
