export default function (doctor, buttonClickAction) {
  const wrapper = document.createElement('div');
  wrapper.className = `doctors-item-container`;
  wrapper.innerHTML = `
    <div class="doctors-item">
      <div class="doctors-item-info">
        <div class="doctors-item-img">
          <img src="${doctor.imageUrl}" alt="doctor image">
        </div>
        <div class="doctors-item-description">
          <div class="doctors-item-description__main-info">
              <h3>${doctor.fullName}</h3>
              <p>${doctor.specialization}</p>
          </div>
          <div class="doctors-item-description__experience">
              <p>${doctor.experience ? doctor.experience + ' років досвіду' : ''}</p>
          </div>
          <!--
          <div class="doctors-item-description__description">
            <p>${doctor.description}</p>
          </div>
          -->
        </div>
      </div>
      <div class="${doctor.status === 'busy' ? 'doctors-item-call--busy' : 'doctors-item-call'}">
          <button type="button" class="doctors-item-call_btn">
              ${doctor.status === 'busy' ? 'Лікар консультує пацієнта' : 'Зателефонувати'}
          </button>
      </div>
    </div>
  `
  wrapper.querySelector('button').addEventListener('click', buttonClickAction.bind(this, doctor));

  return wrapper;
}
