import SendBirdCall from "sendbird-calls";
import {AuthService} from "./auth.service";
import {API_URL} from "../../environments/environment";
import {RequestService} from "./request.service";

export class CallService {
  constructor(appId) {
    SendBirdCall.init(appId);
  }

  startCall(
    recipientId,
    localVideoElement,
    remoteVideoElement,
    toggleMicrophoneBtn,
    toggleCameraBtn,
    toggleAudioBtn,
    openCallWindow,
    closeCallWindow,
    callConnectionError,
    microphoneBtnElement,
    cameraBtnElement,
    audioBtnElement,
    audioLabelElement,
    endCallBtnElement,
    noSoundAvailableElement,
    timerInterval
  ) {
    this.singleCall(recipientId, (res) => {
      this.connectToSendBird(String(res['customer_id']), res['sendbird_token']).then(() => {
        const dialParams = {
          userId: String(res['doctor_id']),
          isVideoCall: true,
          callOption: {
            localMediaView: localVideoElement,
            remoteMediaView: remoteVideoElement,
            audioEnabled: true,
            videoEnabled: true
          }
        };

        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
          .then(function(stream) {
            // this.mediaAccess = SendBirdCall.useMedia({audio: true, video: true});

            const call = SendBirdCall.dial(dialParams, (call, error) => {
              debugger;
              if (error) {
                console.log(error);
                debugger;
              }
            });
            let isRemoteAudioEnabled = true;
            let isRemoteVideoEnabled = true;
            let isSpeakerphone = false;
            let availableDevices;

            call.onConnected = (call) => {
              const allDevices = SendBirdCall.getAvailableAudioInputDevices();

              if (allDevices.length <= 1) {
                audioLabelElement.style.display = 'none';
                noSoundAvailableElement.style.display = 'block';
                openCallWindow();
              } else {
                availableDevices = allDevices.filter(((device) => device.deviceId !== 'default'));
                openCallWindow();
              }
            };

            call.onEnded = (call) => {
              SendBirdCall.removeListener('onAudioInputDeviceChanged');
              // this.mediaAccess.dispose();
              closeCallWindow(call.endResult === 'no_answer' || call.endResult === 'declined' ? call.endResult : 'default');
              window.close();
            };

            call.onRemoteAudioSettingsChanged = (call) => {
              console.log(call);
            };

            endCallBtnElement.onclick = function () {
              clearInterval(timerInterval);
              call.end();
              window.close();
            };

            microphoneBtnElement.onclick = function () {
              toggleMicrophoneBtn();
              if (isRemoteAudioEnabled) {
                call.muteMicrophone();
              } else {
                call.unmuteMicrophone();
              }
              isRemoteAudioEnabled = !isRemoteAudioEnabled;
            };

            cameraBtnElement.onclick = function () {
              toggleCameraBtn();
              if (isRemoteVideoEnabled) {
                call.stopVideo();
              } else {
                call.startVideo();
              }
              isRemoteVideoEnabled = !isRemoteVideoEnabled;
            };

            audioBtnElement.onclick = () => {
              toggleAudioBtn();
              let currentAudio = SendBirdCall.getCurrentAudioInputDevice();
              availableDevices = SendBirdCall.getAvailableAudioInputDevices().filter(((device) => device.deviceId !== 'default' && device.deviceId !== currentAudio.deviceId));
              console.log(availableDevices);
              console.log('all devices: ', SendBirdCall.getAvailableAudioInputDevices());
              console.log('all devices22: ', SendBirdCall.getAvailableAudioOutputDevices());
              if (availableDevices.length > 0) {
                isSpeakerphone = !isSpeakerphone;
                SendBirdCall.selectAudioInputDevice(this.getDevice(availableDevices, isSpeakerphone));
                console.log(isSpeakerphone);
              }
            };

            SendBirdCall.addListener('onAudioInputDeviceChanged', {
              onAudioInputDeviceChanged: (currentDevice, availableDevicesInfo) => {
                availableDevices = SendBirdCall.getAvailableAudioInputDevices().filter(((device) => device.deviceId !== 'default'));
                console.log(availableDevices);
                console.log(isSpeakerphone);
                console.log(currentDevice);
                SendBirdCall.selectAudioInputDevice(this.getDevice(availableDevices, isSpeakerphone));
              }
            });

            SendBirdCall.addListener('onAudioOutputDeviceChanged', {
              onAudioOutputDeviceChanged: (currentDevice, availableDevicesInfo) => {
                console.log('Video device:', currentDevice);
                console.log('Video device info:', availableDevicesInfo);
                //   availableDevices = SendBirdCall.getAvailableAudioInputDevices().filter(((device) => device.deviceId !== 'default'));
                //   console.log(availableDevices);
                //   console.log(isSpeakerphone);
                //   console.log(currentDevice);
                //   SendBirdCall.selectAudioInputDevice(availableDevices[isSpeakerphone ? 0 : 1]);
              }
            });
          })
          .catch(function(err) {
            closeCallWindow('no_permission');
          });
      });
    }, (error) => {
      console.log(error);
      callConnectionError();
    });
  }

  getDevice(availableDevices, isSpeakerphone) {
    if (isSpeakerphone) {
      return availableDevices.filter((device) => device.label.includes('Speakerphone'))[0];
    } else {
      return availableDevices[availableDevices.length - 1];
    }
  }

  // private

  async connectToSendBird(userId, accessToken) {
    await SendBirdCall.authenticate({ userId: userId, accessToken: accessToken });
    await SendBirdCall.connectWebSocket();
  }

  // TODO: Remove callback. Use Promise
  async singleCall(recipientId, callback, callbackError) {
    const authService = new AuthService();
    const requestService = new RequestService();
    const accessToken = localStorage.getItem('Access-token');

    await authService.setSourceToken(API_URL + '/api/v1/telemedicine/single_calls/prepare', {
      headers: {
        'Access-token': accessToken,
        'Content-Type': 'application/json',
        'accept': 'application/json',
        'Accept-Language': 'ru'
      },
    }, (url, option) => {
      requestService.request(url, {
        method: 'POST',
        headers: option.headers,
        body: JSON.stringify({
          "doctor_id": recipientId
        })
      }).then((res) => {
        callback(JSON.parse(res['response']));
      }, (error) => {
        callbackError(error);
      });
    });
  }
}
