import NoSleep from 'nosleep.js';

export class NoSleepService {
  constructor() {
    this.noSleep = new NoSleep();
  }

  initNoSleep(element) {
    element.addEventListener('click', function enableNoSleep() {
      element.removeEventListener('click', enableNoSleep, false);
      this.noSleep.enable();
    }, false);
  }

  removeNoSleep() {
    this.noSleep.disable();
  }
}
