export class RequestService {
  constructor() {
  }

  request(url, options) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.open(options.method, url, true);

      Object.keys(options.headers).forEach((key) => {
        xhr.setRequestHeader(key, options.headers[key]);
      });

      xhr.onreadystatechange = (res) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(res.target);
          } else {
            reject(new Error('server request failed'));
          }
        }
      };

      xhr.onerror = (err) => {
        reject(new Error('server request failed'));
      }

      xhr.send(options.body);
    });
  }
}
