export class DoctorModel {

  constructor(id, clientId, imageUrl, callName, fullName, description, specialisation, experience, status) {
    this.id = id;
    this.clientId = clientId;
    this.imageUrl = imageUrl;
    this.callName = callName;
    this.fullName = fullName;
    this.description = description;
    this.specialization = specialisation;
    this.experience = experience;
    this.status = status;
  }

}
