export class TokenService {

  constructor() {
    this._TOKEN_KEY = 'tokenData';
    this._EXPIRES_KEY = 'expiresOn';
  }

  saveToken(token) {
    const expiresOn = Date.now() + (token['expires_in'] * 1000);

    this._token = token;
    sessionStorage.setItem(this._TOKEN_KEY, JSON.stringify(token));

    this._expiresOn = expiresOn;
    sessionStorage.setItem(this._EXPIRES_KEY, JSON.stringify(expiresOn));
  }

  get token() {
    if (!this._token) {
      this._token = this._getItemData(this._TOKEN_KEY);
    }
    return this._token;
  }

  get expiresOn() {
    if (!this._expiresOn) {
      this._expiresOn = this._getItemData(this._EXPIRES_KEY);
    }
    return this._expiresOn;
  }

  _getItemData(key) {
    return JSON.parse(sessionStorage.getItem(key));
  }
}
